import * as React from "react";
import { styled } from "@mui/material/styles";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&::before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.8rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark"
      ? "rgba(255, 255, 255, .05)"
      : "rgba(0, 0, 0, .03)",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

export default function CustomizedAccordions() {
  const [expanded, setExpanded] = React.useState("panel1");

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <div>
      <Accordion
        expanded={expanded === "panel1"}
        onChange={handleChange("panel1")}
        className="Accordion_content"
      >
        <AccordionSummary
          className="Summary_content"
          aria-controls="panel1d-content"
          id="panel1d-header"
        >
          <Typography>
            How can I book my air travel most efficiently?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            You can email us at admin@brightfare.co.uk, or book online at
            www.brightfare.co.uk website using your debit or credit card,
            Contact us via our Online Chat Support or give us a call or Whatsaap
            on 0203 8313 492. We are open 24 hours a day 7 days a week.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion
        expanded={expanded === "panel2"}
        onChange={handleChange("panel2")}
      >
        <AccordionSummary
          className="Summary_content"
          aria-controls="panel2d-content"
          id="panel2d-header"
        >
          <Typography>
            Can Brightfare Travels Ltd. provides discounts on the economy,
            business and first-class fares?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            We have the best prices in the market to provide to our customers.
            We can offer the best discounts on the economy, premium economy,
            business and first-class cabins.{" "}
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion
        expanded={expanded === "panel3"}
        onChange={handleChange("panel3")}
      >
        <AccordionSummary
          className="Summary_content"
          aria-controls="panel3d-content"
          id="panel3d-header"
        >
          <Typography>
            Is it cheaper to book my tickets through Brightfare Travels Ltd. or
            online myself?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            We have access to better fares than you can get online. We are
            consolidators and source these fares at bulk rates. Plus, due to our
            volume of sales, we are given discounts and have availability to
            fares and seats that may not be available on some online portals.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion
        expanded={expanded === "panel4"}
        onChange={handleChange("panel4")}
      >
        <AccordionSummary
          className="Summary_content"
          aria-controls="panel4d-content"
          id="panel4d-header"
        >
          <Typography>
            {" "}
            How are these fares different than buying a ticket from the airline
            directly?{" "}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            These tickets are for the most part very similar to tickets you
            would buy directly from the airlines. You can request special meals,
            get advanced seat assignments and almost always acquire frequent
            flyer mileage. An advantage is that these tickets are often
            refundable (with a cancel penalty).
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion
        expanded={expanded === "panel5"}
        onChange={handleChange("panel5")}
      >
        <AccordionSummary
          className="Summary_content"
          aria-controls="panel5d-content"
          id="panel5d-header"
        >
          <Typography>How can I pay for my ticket?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            All rates quoted can be paid with a Debit Card, Credit Card, Payment
            Link or You can also pay the amount in our Business bank account.{" "}
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion
        expanded={expanded === "panel6"}
        onChange={handleChange("panel6")}
      >
        <AccordionSummary
          className="Summary_content"
          aria-controls="panel6d-content"
          id="panel6d-header"
        >
          <Typography>How and when will I receive my ticket?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Passenger will get the tickets within 24 hours after receiving full
            payment. That will be an E-Ticket which customer can just print out
            and go to the airport otherwise if customer doesn’t have the printer
            access then customer can just go to the airport with E-Ticket number
            only.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === "panel7"}
        onChange={handleChange("panel7")}
      >
        <AccordionSummary
          className="Summary_content"
          aria-controls="panel7d-content"
          id="panel7d-header"
        >
          <Typography>What if I need to cancel or change my ticket?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            If you wish to amend a booking/order or wish to enquire if a
            specific change can be made, then please get in touch with our team
            for further assistance. We will then be able to check the booking
            details and get back to you as soon as possible to advise if the
            amendment is possible and if it will incur a refund or additional
            charge. In case of any additional cost involved, it will be advised
            before any amendment occurs.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === "panel8"}
        onChange={handleChange("panel8")}
      >
        <AccordionSummary
          className="Summary_content"
          aria-controls="panel8d-content"
          id="panel8d-header"
        >
          <Typography>
            Can I use someone else's credit card to pay for a ticket?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Yes, but you cannot pay the full payment of the ticket with someone
            else’s credit or debit card. You can only pay a deposit and rest of
            the amount you can pay by bank transfer or by cash in our business
            account.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === "panel9"}
        onChange={handleChange("panel9")}
      >
        <AccordionSummary
          className="Summary_content"
          aria-controls="panel9d-content"
          id="panel9d-header"
        >
          <Typography>
            My name is spelt incorrectly on my flight ticket, what can I do?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            It is not possible to change your name on an airline ticket. The
            ticket will have to be cancelled and a new ticket will need to be
            issued (cancellation penalties will apply). When booking, please
            review your information carefully and ensure that the name of each
            passenger is entered correctly. If there is a minor error after you
            have placed a booking, please immediately contact our team member
            for the correction.
            <br />
            We will need to contact the airline to request a name change and we
            cannot guarantee that they will be able to make the change.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === "panel10"}
        onChange={handleChange("panel10")}
      >
        <AccordionSummary
          className="Summary_content"
          aria-controls="panel10d-content"
          id="panel10d-header"
        >
          <Typography>How do I cancel my ticket?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Lower priced tickets are mostly non-refundable, but in some cases,
            airlines will charge a cancellation fee if you wish to cancel your
            booking. For any further details please contact our team.{" "}
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === "panel11"}
        onChange={handleChange("panel11")}
      >
        <AccordionSummary
          className="Summary_content"
          aria-controls="panel11d-content"
          id="panel11d-header"
        >
          <Typography>
            What is the refund process and how long should I expect to wait for
            a refund?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            An airline refund takes 8 to 18 weeks to process, depending on where
            you book and when you travel. We handle the whole process from our
            end to ensure you receive your refund as quickly as possible. Once
            we have received the money, and we have processed it, you will
            receive it. This varies depending on the airline and due to the
            pandemic, there are still delays with us receiving refunds, however,
            we do take the stress away by chasing the airlines for you.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === "panel12"}
        onChange={handleChange("panel12")}
      >
        <AccordionSummary
          className="Summary_content"
          aria-controls="panel12d-content"
          id="panel12d-header"
        >
          <Typography>
            Where i can check the travel advisory related to my trip?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            The British government has advised against all but essential travel.
            Please do check the entry requirements for your destination. Please
            also check the guidelines from here:
            https://www.gov.uk/foreign-travel-advice Please also check with a
            member of our team for
          </Typography>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
