import React from "react";

const Wattsapp = () => {
  return (
    <>
      <div className="wattsapp">
        <a
          href="https://wa.me/442038313492?text=Hi there..How can I help you ?"
          target="_blank"
          className="whatsapp_float"
        >
          <i class="fa-brands fa-whatsapp whatsapp-icon"></i>
        </a>
      </div>
    </>
  );
};

export default Wattsapp;
