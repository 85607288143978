import React, { useState, useEffect } from "react";
import mainImg from "../images/Image Main Page.jpg";
import Designation from "../images/Designation.jpg";
import introo from "../images/introo.png";
import Why_we_are_best from "../images/Why_we_are_best.png";
import Why_Need_Agent from "../images/Why_Need_Agent.jpeg";
import vision from "../images/vision.jpg";
import emailjs from "emailjs-com";
import FlightIcon from "@mui/icons-material/Flight";
import { useNavigate } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { fadeImages } from "./API/AirCodeapi";
import { AirlinesAPI } from "./API/AirlinesAPI";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const Home = () => {
  const navigate = useNavigate();
  // ================= DYAMICALLLY CAHNGE TITLE FUNCTION ========
  const helmet = (pageTitle) => {
    const fullTitle = `Brightfare Ltd | ${pageTitle}`;
    document.title = fullTitle;
  };
  // ========= SLIDER FUNCTION ===========
  const setting = {
    infinite: true,
    dots: true,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 2000,
    fade: true,
    fadeSpeed: 1000,
  };
  // HOOK FOR ACTIVE CLASS ON FORM
  const [activeButton, setActiveButton] = useState("return");
  const [showReturn, setShowReturn] = useState(true);
  const handleButtonClick = (buttonType) => {
    setActiveButton(buttonType);
    if (buttonType === "oneWay") {
      console.log("oneway");
      setShowReturn(false);
    } else if (buttonType === "return") {
      setShowReturn(true);
    }
  };
  const handleLinkClick = () => {
    window.scrollTo(0, 0);
  };
  //HOOK USING FETCH THE API
  const [data, setData] = useState([]);
  useEffect(() => {
    fetch(
      "https://raw.githubusercontent.com/L1fescape/airport-codes/master/airports.json"
    ).then((res) =>
      res.json().then((output) => {
        setData(output);
      })
    );
  });
  // =================== FIRST SEARCHING FUNCTION HOOK ===========================
  const [filteredData, setFilteredData] = useState("");
  const [selectedItem, setSelectedItem] = useState(null);
  // SEARCHING INPUT AIRPORTS FUNCTION
  const onchangetext = (event) => {
    setFilteredData(event.target.value);
    setSelectedItem(null);
  };
  const onSearch = (item) => {
    setSelectedItem(item);
    setFilteredData("");
  };
  // ==================== SECOND  SEARCHING INPUT AIRPORTS FUNCTION ==================================
  const [filterData, setFilterData] = useState("");
  const [selectItem, setSelectItem] = useState(null);
  const Search = (searcht) => {
    setSelectItem(searcht);
    setFilterData("");
    console.log("Destination", searcht);
  };
  const changetext = (event) => {
    setFilterData(event.target.value);
    setSelectItem(null);
  };

  // AIRLINES SEARCHING FUNCTION
  const [filterAir, setFilterAir] = useState("");
  const [selectAir, setSelectAir] = useState(null);

  const searchAir = (airline) => {
    setSelectAir(airline);
    setFilterAir("");
    console.log("Selected Airline:", airline);
  };

  const changeAirlines = (event) => {
    setFilterAir(event.target.value);
    setSelectAir(null);
  };

  //  EMAIL NOTIFICATION FUNCTION GETTING THE EMAIL
  const SendEmail = (e) => {
    e.preventDefault();
    console.log("Selected Button ID:", activeButton);
    emailjs
      .sendForm(
        "service_3ciis79",
        "template_zkjipct",
        e.target,
        "3Ftc-ODHIbWAThyqn"
      )
      .then((res) => {
        console.log(res);
      })
      .catch((err) => console.log("Clicked", err));
    navigate("/Confirmation");

    // toast.success("🦄 Wow so easy!", {
    //   position: "top-center",
    //   autoClose: 5000,
    //   hideProgressBar: false,
    //   closeOnClick: true,
    //   pauseOnHover: true,
    //   draggable: true,
    //   progress: undefined,
    //   theme: "dark",
    // });
  };

  return (
    <>
      <div>
        {/* =================  FLIGHT SECTION ============== */}
        <section className="d-home">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-12">
                <div className="row">
                  <div className="col-lg-12">
                    <form onSubmit={SendEmail}>
                      <div className="FLightModes">
                        <label
                          htmlFor="returnButton"
                          className={`btn ${
                            activeButton !== "oneWay" ? "active" : ""
                          }`}
                        >
                          <input
                            type="radio"
                            id="returnButton"
                            name="check"
                            checked={activeButton !== "oneWay"}
                            onChange={() => handleButtonClick("return")}
                            value="Return"
                          />
                          <FlightIcon />
                          Return
                        </label>
                        <label
                          htmlFor="oneWayButton"
                          className={`btn ${
                            activeButton === "oneWay" ? "active" : ""
                          }`}
                        >
                          <input
                            type="radio"
                            name="check"
                            id="oneWayButton"
                            checked={activeButton === "oneWay"}
                            onChange={() => handleButtonClick("oneWay")}
                            value="Oneway"
                          />
                          <FlightIcon />
                          OneWay
                        </label>
                      </div>
                      <div className="d-form">
                        <div className="container-fluid">
                          <div className="col-lg-12">
                            <div className="row">
                              <div className="col-lg-6 col-md-6 col-sm-12">
                                <label>FLYING FROM</label>
                                <div className="form-group">
                                  <input
                                    required
                                    autoComplete="off"
                                    type="text"
                                    name="origin"
                                    value={
                                      selectedItem
                                        ? `${selectedItem.name} | ${selectedItem.city} | ${selectedItem.country} | ${selectedItem.iata}`
                                        : filteredData
                                    }
                                    onChange={onchangetext}
                                    placeholder="City or Airport"
                                    className="form-control"
                                  />
                                </div>
                                <div className="dataResult">
                                  {data
                                    .filter((item) => {
                                      const searchItem =
                                        filteredData.toLowerCase();
                                      const name = item.name.toLowerCase();
                                      const city = item.city.toLowerCase();
                                      const country =
                                        item.country.toLowerCase();
                                      const iata = item.iata.toLowerCase();

                                      // Filter based on multiple criteria
                                      return (
                                        searchItem &&
                                        (name.startsWith(searchItem) ||
                                          city.startsWith(searchItem) ||
                                          country.startsWith(searchItem) ||
                                          iata.startsWith(searchItem)) &&
                                        name !== searchItem
                                      );
                                    })
                                    .map((item, index) => (
                                      <p
                                        key={index}
                                        className="dataItem"
                                        onClick={() => onSearch(item)}
                                      >
                                        {item.name} | {item.city} |{" "}
                                        {item.country} | {item.iata}
                                      </p>
                                    ))}
                                </div>
                              </div>
                              <div className="col-lg-6 col-md-6 col-sm-12">
                                <label>FLYING TO</label>
                                <div className="form-group">
                                  <input
                                    required
                                    autoComplete="off"
                                    autoCorrect="yes"
                                    aria-autocomplete="none"
                                    type="text"
                                    name="Destination"
                                    value={
                                      selectItem
                                        ? `${selectItem.name} | ${selectItem.city} | ${selectItem.country} | ${selectItem.iata}`
                                        : filterData
                                    }
                                    onChange={changetext}
                                    placeholder="City or Airport"
                                    className="form-control"
                                  />
                                </div>
                                <div className="dataResult">
                                  {data
                                    .filter((ites) => {
                                      const searchIt = filterData.toLowerCase();
                                      const fName = ites.name.toLowerCase();
                                      const city = ites.city.toLowerCase();
                                      const country =
                                        ites.country.toLowerCase();
                                      const iata = ites.iata.toLowerCase();

                                      // Filter based on multiple criteria
                                      return (
                                        searchIt &&
                                        (fName.startsWith(searchIt) ||
                                          city.startsWith(searchIt) ||
                                          country.startsWith(searchIt) ||
                                          iata.startsWith(searchIt)) &&
                                        fName !== searchIt
                                      );
                                    })
                                    .map((tem, index) => (
                                      <p
                                        key={index}
                                        className="dataItem"
                                        onClick={() => Search(tem)}
                                      >
                                        {tem.name} | {tem.city} | {tem.country}{" "}
                                        | {tem.iata}
                                      </p>
                                    ))}
                                </div>
                              </div>
                              <div
                                className={
                                  showReturn
                                    ? "col-lg-3  col-md-3 col-6"
                                    : "col-lg-6  col-md-6 col-12"
                                }
                              >
                                <div className="form-group Departure">
                                  <label>Departure</label>
                                  <input
                                    type="date"
                                    className="form-control"
                                    // value="dd/mm/yy"
                                    name="Departure"
                                    required=""
                                  ></input>
                                </div>
                              </div>
                              {showReturn && (
                                <div className="col-lg-3 col-6">
                                  <div className="form-group">
                                    <label>Return</label>
                                    <input
                                      type="date"
                                      placeholder="dd/mm/yy"
                                      className="form-control"
                                      name="arrival"
                                      required=""
                                    ></input>
                                  </div>
                                </div>
                              )}
                              <div className="col-lg-2 col-md-6 col-4">
                                <div className="form-group">
                                  <label>ADULTS</label>
                                  {/*  CHILDREN {{(CHILDREN)}}    INFANTS {{(INFANTS)}}  */}
                                  <select
                                    className="form-control formImput"
                                    id="exampleFormControlTextarea1  ADULT"
                                    name="ADULTS"
                                  >
                                    {/* <option value="0">0</option> */}
                                    <option value="1">1</option>
                                    <option value="2">2</option>
                                    <option value="3">3</option>
                                    <option value="4">4</option>
                                    <option value="5">5</option>
                                    <option value="6">6</option>
                                    <option value="7">7</option>
                                    <option value="8">8</option>
                                    <option value="9">9</option>
                                  </select>
                                </div>
                              </div>
                              <div className="col-lg-2 col-md-4 col-4">
                                <div className="form-group">
                                  <label>CHILDREN</label>
                                  <select
                                    className="form-control formImput"
                                    id="exampleFormControlTextarea1"
                                    name="CHILDREN"
                                  >
                                    <option value="0">0</option>
                                    <option value="1">1</option>
                                    <option value="2">2</option>
                                    <option value="3">3</option>
                                    <option value="4">4</option>
                                    <option value="5">5</option>
                                    <option value="6">6</option>
                                    <option value="7">7</option>
                                    <option value="8">8</option>
                                    <option value="9">9</option>
                                  </select>
                                </div>
                              </div>
                              <div className="col-lg-2 col-md-6 col-4">
                                <div className="form-group ">
                                  <label>INFANTS</label>
                                  <select
                                    className="form-control formImput"
                                    id="exampleFormControlTextarea1"
                                    name="INFANTS"
                                  >
                                    <option value="0">0</option>
                                    <option value="1">1</option>
                                    <option value="2">2</option>
                                    <option value="3">3</option>
                                    <option value="4">4</option>
                                    <option value="5">5</option>
                                    <option value="6">6</option>
                                    <option value="7">7</option>
                                    <option value="8">8</option>
                                    <option value="9">9</option>
                                  </select>
                                </div>
                              </div>
                              <div className="col-lg-3 col-md-6 col-6">
                                <div className="form-group">
                                  <label>Travel Class</label>
                                  <select
                                    className="form-control formImput"
                                    id="exampleFormControlTextarea1"
                                    name="CLASS"
                                  >
                                    <option disabled selected value="Any">
                                      Select Cabin Class
                                    </option>
                                    <option value="Economy">Economy</option>
                                    <option value="Enhanced Economy">
                                      Enhanced Economy
                                    </option>
                                    <option value="Bussiness">Bussiness</option>
                                    <option value="First">First</option>
                                  </select>
                                </div>
                              </div>
                              <div className="col-lg-3 col-md-6 col-6">
                                <div className="form-group">
                                  <label>Airline</label>
                                  <input
                                    autoComplete="off"
                                    required
                                    type="text"
                                    className="form-control"
                                    placeholder="Any Specific Flight"
                                    name="airline"
                                    value={
                                      selectAir
                                        ? `${selectAir.AirName}`
                                        : filterAir
                                    }
                                    onChange={changeAirlines}
                                  />
                                </div>
                                <div className="AirlinesResult">
                                  {AirlinesAPI.filter((airline) => {
                                    const searchAirlines =
                                      filterAir.toLowerCase();
                                    const name = airline.AirName.toLowerCase();
                                    // Filter based on multiple criteria
                                    return (
                                      searchAirlines &&
                                      name.includes(searchAirlines)
                                    );
                                  }).map((airline, index) => (
                                    <p
                                      key={index}
                                      className="AirlinesItem"
                                      onClick={() => searchAir(airline)}
                                    >
                                      {airline.AirName}
                                    </p>
                                  ))}
                                </div>
                              </div>
                              <div className="col-lg-2 col-md-6 col-12">
                                <div className="form-group ">
                                  <label>Full Name</label>
                                  <input
                                    autoComplete="off"
                                    required
                                    type="Text"
                                    name="name"
                                    placeholder="Name"
                                    className="form-control"
                                  />
                                </div>
                              </div>
                              <div className="col-lg-2 col-md-6 col-12">
                                <div className="form-group ">
                                  <label>Email</label>
                                  <input
                                    autoComplete="off"
                                    required
                                    type="email"
                                    name="email"
                                    placeholder="Email"
                                    className="form-control"
                                  />
                                </div>
                              </div>
                              <div className="col-lg-2 col-md-6 col-12">
                                <div className="form-group ">
                                  <label>Contact</label>
                                  <input
                                    autoComplete="off"
                                    required
                                    type="tel"
                                    maxLength={11}
                                    name="number"
                                    placeholder="Number"
                                    className="form-control"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <button
                          type="submit"
                          onClick={() => {
                            handleLinkClick();
                            helmet("Confimation");
                          }}
                          className="btn btn-warning SubmitBTn"
                        >
                          Show Flights
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <br />
        {/* ==================== SHOW IMG ===================== */}
        <section>
          <div className="Designation">
            <img
              src={Designation}
              alt="Designation"
              className="img-fluid img-responsive"
            />
          </div>
          <br />
          <div className="button-container">
            <button
              onClick={() => {
                handleLinkClick();
                navigate("/Contact");
              }}
              className="btn btn-warning"
            >
              Book Now
            </button>
          </div>
        </section>
        <br />
        {/* ==================== IMG WITH TEXT 1 ===================== */}
        <div className="company_vision">
          <section className="Why_we_are_best">
            <div className="container">
              <div className="row">
                <div className="col-lg-6 col-md-6 col-sm-12">
                  <br />
                  <br />
                  <h3>Why We are the Best Company?</h3>
                  <br />
                  <p>
                    We are committed to ensuring that every aspect of your trip
                    is seamless, from booking to arrival. Our team goes above
                    and beyond to tailor each experience to your preferences,
                    ensuring that every detail is taken care of. With our
                    extensive network of partners and resources, we have the
                    flexibility to accommodate any request, no matter how
                    unique. Whether you're seeking a last-minute reservation or
                    special accommodations, we have the expertise to make it
                    happen. Our dedication to customer satisfaction means that
                    we are always available to assist you, day or night. You can
                    trust us to handle any challenges that may arise, providing
                    peace of mind throughout your journey.
                  </p>
                </div>
                <br />
                <br />
                <div className="col-lg-6 col-md-6 col-sm-12">
                  <img
                    src={Why_we_are_best}
                    alt="introo"
                    className="img-fluid img-responsive"
                  />
                </div>
              </div>
            </div>
          </section>
          {/* ==================== IMG WITH TEXT 2 ===================== */}
          <section className="brightfair_vision">
            <div className="container">
              <div className="row">
                <div className="col-lg-6 col-md-6 col-sm-12">
                  <img
                    src={vision}
                    alt="vision"
                    className="img-fluid img-responsive vision_web"
                  />
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12">
                  <br />
                  <br />
                  <h3>Brightfare Vision</h3>
                  <br />
                  <p>
                    our vision is to make travel simple and fun for all,
                    Inspiring people to go further and share more. It’s a vision
                    that encourages people to reach higher, to broaden their
                    lives and the lives of others and to seek experiences worth
                    sharing We will achieve our new vision with a new mission.
                    By putting people first, we’ll push boundaries and inspire
                    the world’s most shareable experiences. We made the
                    searching and booking process simpler and stress-free as it
                    ought to be.
                  </p>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12 d-lg-none">
                  <img
                    src={vision}
                    alt="vision"
                    className="img-fluid img-responsive vision_mbl"
                  />
                </div>
                <br />
                <br />
              </div>
            </div>
          </section>
          {/* ==================== IMG WITH TEXT 3 ===================== */}
          <section className="company_intro">
            <div className="container">
              <div className="row">
                <div className="col-lg-6 col-md-6 col-sm-12">
                  <br />
                  <br />
                  <h3>Company Introduction</h3>
                  <br />
                  <p>
                    Brightfare Travels Ltd is a full-service online travel
                    agency based in United Kingdom that provides both leisure
                    and corporate as ‘one-stop’ travel management services. We
                    are experienced experts in the travel and tourism management
                    field, to submit the best quality service to our customers,
                    we make it easier for our customers to travel by offering
                    everything they need for a trip in one place. Lowest rates
                    in the market with high quality as we maintain an edge over
                    our competitors by ensuring our clients’ best prices and
                    services. Value is what we aim.
                  </p>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12">
                  <img
                    src={introo}
                    alt="introo"
                    className="img-fluid img-responsive"
                  />
                </div>
              </div>
            </div>
          </section>
          {/* ==================== IMG WITH TEXT 4 ===================== */}
          <section className="Why_Need_Agent">
            <div className="container">
              <div className="row">
                <div className="col-lg-6 col-md-6 col-sm-12">
                  <img
                    src={Why_Need_Agent}
                    alt="Why_Need_Agent"
                    className="img-fluid img-responsive Why_Need_Agent_web"
                  />
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12">
                  <br />
                  <br />
                  <h3>Why Need Travel Agent?</h3>
                  <br />
                  <p>
                    <ul>
                      <li> Saves Your Time</li>
                      <li>Can Handle the Unexpected</li>
                      <li>Have Been There, Done That</li>
                      <li>Offer Expert Recommendations</li>
                      <li>Take the Stress Out of Planning </li>
                      <li>Can Help You See the Big Picture </li>
                      <li>A Human Connection that Travels with You</li>
                      <li>Can Help You Navigate Changing Travel Conditions</li>
                      <li>
                        Travel Options are More Complex Than They Used to Be
                      </li>
                      <li>
                        Travel Agents Not Only Save Your Money, They Deliver
                        more Value for Your Vacation.
                      </li>
                    </ul>
                  </p>
                </div>
                <br />
                <div className="col-lg-6 col-md-6 col-sm-12 d-lg-none">
                  <img
                    src={Why_Need_Agent}
                    alt="vision"
                    className="img-fluid img-responsive Why_Need_Agent_mbl"
                  />
                </div>
              </div>
            </div>
          </section>
        </div>
        {/* ==================== IMG SLIDER ===================== */}
        <section className="container Slider">
          <Slider {...setting}>
            {fadeImages.map((img, index) => (
              <div key={index}>
                <img
                  className="img-fluid img-responsive"
                  src={img.url}
                  alt={img.alt}
                />
              </div>
            ))}
          </Slider>
        </section>

        {/* ==================== PAGE IMAGE =========== */}
        <section className="main_img container mt-5">
          <img
            src={mainImg}
            alt="mainImg"
            className="img-fluid img-responsive"
          />
        </section>
        <br />
        <br />
      </div>
      <ToastContainer />
    </>
  );
};

export default Home;
