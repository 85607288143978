import React, { useState, useEffect } from "react";
import "./App.css";
import Navbar from "../src/Components/Navbar";
import Home from "../src/Components/Home";
import Hajj from "../src/Components/Hajj";
import Fly from "../src/Components/Fly";
import Contact from "../src/Components/Contact";
import Footer from "../src/Components/Footer";
import About from "../src/Components/About";
import Confirmation from "../src/Components/Confirmation";
import Wattsapp from "../src/Components/Wattsapp";
import Term from "../src/Components/Term";
import Preloader from "./Components/Preloader";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Routes, Route } from "react-router-dom";
function App() {
  const notify = () => toast("Wow so easy!");
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    setLoading(true);
    const timer = setTimeout(() => {
      setLoading(false);
    }, 1000);
    return () => clearTimeout(timer);
  }, []);
  return (
    <div>
      {loading ? (
        <Preloader />
      ) : (
        <>
          <Navbar />
          <Wattsapp />
          <Routes>
            <Route exact path="/" element={<Home />} />
            <Route exact path="/Fly" element={<Fly />} />
            <Route exact path="/Hajj" element={<Hajj />} />
            <Route exact path="/Contact" element={<Contact />} />
            <Route exact path="/about" element={<About />} />
            <Route exact path="/Confirmation" element={<Confirmation />} />
            <Route exact path="/Term" element={<Term />} />
          </Routes>
          <Footer />
        </>
      )}
      <ToastContainer />
    </div>
  );
}

export default App;
