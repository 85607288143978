import React from "react";
import hajj from "../images/hajj.png";
const Hajj = () => {
  return (
    <div>
      <section>
        <div className="container-fluid nav-bg hujjimg">
          <div className="row">
            <div className="S-service">
              <div className="container">
                <div className="row">
                  {/*Column 1 seo*/}
                  <div className=" pt-5 col-lg-6 col-sm-6"></div>
                  {/*column 2 seo*/}

                  <div className="col-lg-6 col-sm-6">
                    <div className="s-img">
                      <br />
                      <img
                        className="img-fluid img-responsive"
                        src={hajj}
                        alt=""
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <br />
        <br />
        <div className="container hujjContent">
          <div className="row">
            <div className="col-12 mx-auto">
              <h2>Welcome To Brightfare Hajj & Umrah Services</h2>
              <br />
              <p>
                <strong>Brightfare Hajj and Umrah Services:</strong> offer
                expert travel consultants and a customer service team with
                experience which make us one of the leading companies in
                delivering Hajj and Umrah Packages as smoothly as possible.
              </p>
              <br />
              <p>
                <strong>Brightfare Hajj and Umrah Services</strong> has a team
                of dedicated professionals who are on call whether by phone or
                in person at our offices based in London. We also have a team of
                professional volunteers who are able to liaise with you across
                the UK. When booking your Hajj Package with Brightfare Hajj and
                Umrah services you will have the confidence in knowing we as an
                organization over the years have served thousands of pilgrims
                with Hajj and Umrah Packages.
              </p>
              <br />
              <p>
                <strong>Brightfare Hajj and Umrah Services</strong> believes
                when undertaking your first Hajj journey preparation is
                absolutely paramount in ensuring the experience you have is one
                which is memorable and spiritual.
              </p>
              <br />
              <p>
                <strong>Brightfare Hajj and Umrah Services</strong> has a number
                of hajj packages for you to choose from, we also review our
                logistical operations after each and every Hajj to ensure we
                improve for the following year. We constantly strive to improve
                our Hajj Packages and implement changes which make your journey
                as smooth as possible so that you can focus solely on your
                ibadah.
              </p>
              <br />
              <p>
                We constantly work with the best airlines and tour operators,
                bringing you the greatest travel packages at the right price
                with the best support available on the market.
              </p>
              <br />
              <h3>
                Call or Whatsaap now for a quote 0203 831 3492 or contact us via
                email at admin@brightfare.co.uk , let Brightfare Travels Ltd
                secure your Hajj or Umrah package today.
              </h3>
              <br />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Hajj;
