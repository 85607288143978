export const AirlinesAPI = [
  { id: 1, AirName: "Aegean Airlines" },
  { id: 2, AirName: "Air Lingus" },
  { id: 3, AirName: "Aeroflot" },
  { id: 4, AirName: "Aerolineas " },
  { id: 5, AirName: "Air Arabia" },
  { id: 6, AirName: "Air Astana" },
  { id: 7, AirName: "Air Austral" },
  { id: 8, AirName: "Air Baltic" },
  { id: 9, AirName: "Air Belgium" },
  { id: 10, AirName: "Air Canada" },
  { id: 11, AirName: "Air Caraibes" },
  { id: 12, AirName: "Air China" },
  { id: 13, AirName: "Air Corsica" },
  { id: 14, AirName: "Air Dolomiti" },
  { id: 15, AirName: "Air Europa" },
  { id: 16, AirName: "Air France" },
  { id: 17, AirName: "Air India" },
  { id: 18, AirName: "Air India Express" },
  { id: 19, AirName: "Air Macau" },
  { id: 20, AirName: "Air Malta" },
  { id: 21, AirName: "Air Mauritius" },
  { id: 22, AirName: "Air Namibia" },
  { id: 23, AirName: "Air New Zealand" },
  { id: 24, AirName: "Air North" },
  { id: 25, AirName: "Air Seoul" },
  { id: 26, AirName: "Air Serbia" },
  { id: 27, AirName: "Air Tahiti Nui" },
  { id: 28, AirName: "Air Transat" },
  { id: 29, AirName: "Air Vanuatu" },
  { id: 30, AirName: "Air Asia" },
  { id: 31, AirName: "Air calin" },
  { id: 32, AirName: "Alaska Airlines" },
  { id: 33, AirName: "Alitalia" },
  { id: 34, AirName: "Allegiant" },
  { id: 35, AirName: "American Airlines" },
  { id: 36, AirName: "Austrian" },
  { id: 37, AirName: "Bamboo Airways" },
  { id: 38, AirName: "Bangkok Airways" },
  { id: 39, AirName: "British Airways" },
  { id: 40, AirName: "Brussels Airlines" },
  { id: 41, AirName: "Caribbean Airlines" },
  { id: 42, AirName: "Cathay Dragon" },
  { id: 43, AirName: "Cathay Pacific" },
  { id: 44, AirName: "Cayman Airways" },
  { id: 45, AirName: "CEBU Pacific Air" },
  { id: 46, AirName: "China Airlines" },
  { id: 47, AirName: "China Eastern" },
  { id: 48, AirName: "China Southern" },
  { id: 49, AirName: "Copa Airlines" },
  { id: 50, AirName: "Croatia Airlines" },
  { id: 51, AirName: "Czech Airlines" },
  { id: 52, AirName: "Delta" },
  { id: 53, AirName: "EasyJet" },
  { id: 54, AirName: "Edelweiss Air" },
  { id: 55, AirName: "Egyptair" },
  { id: 56, AirName: "Emirates" },
  { id: 57, AirName: "Ethiopian Airlines" },
  { id: 58, AirName: "Etihad" },
  { id: 59, AirName: "Eurowings" },
  { id: 60, AirName: "EVA Air" },
  { id: 61, AirName: "Fiji Airways" },
  { id: 62, AirName: "Finnair" },
  { id: 63, AirName: "flydubai" },
  { id: 64, AirName: "Fly One" },
  { id: 65, AirName: "French bee" },
  { id: 66, AirName: "Frontier" },
  { id: 67, AirName: "Garuda Indonesia" },
  { id: 68, AirName: "Gulf Air" },
  { id: 69, AirName: "Hainan Airlines" },
  { id: 70, AirName: "Hawaiian Airlines" },
  { id: 71, AirName: "Helvetic Airways" },
  { id: 72, AirName: "HK Express" },
  { id: 73, AirName: "Hong Kong Airlines" },
  { id: 74, AirName: "Hahn Air" },
  { id: 75, AirName: "Iberia" },
  { id: 76, AirName: "Iceland Air" },
  { id: 77, AirName: "IndiGo Airlines" },
  { id: 78, AirName: "InterJet" },
  { id: 79, AirName: "Japan Airlines" },
  { id: 80, AirName: "JetBlue" },
  { id: 81, AirName: "Kenya Airways" },
  { id: 82, AirName: "KLM" },
  { id: 83, AirName: "Korean Air" },
  { id: 84, AirName: "Kulula" },
  { id: 85, AirName: "Lion Airlines" },
  { id: 86, AirName: "LOT Polish Airlines" },
  { id: 87, AirName: "Lufthansa" },
  { id: 88, AirName: "Luxair" },
  { id: 89, AirName: "Malaysia Airlines" },
  { id: 90, AirName: "Middle East Airlines" },
  { id: 91, AirName: "Nok Air" },
  { id: 92, AirName: "Nordwind Airlines" },
  { id: 93, AirName: "Norwegian Air UK" },
  { id: 94, AirName: "Oman Air" },
];
