import React from "react";
import { Link } from "react-router-dom";
import LinesImg1 from "../images/LinesImg1.png";
import LinesImg2 from "../images/LinesImg2.png";
const Footer = () => {
  // ======== Navigate ===========

  const handleLinkClick = () => {
    window.scrollTo(0, 0);
  };
  return (
    <div>
      <hr />
      <div className="images d-flex">
        <img
          className="img1 img-fluid img-responsive"
          src={LinesImg1}
          alt="img1"
        />
        <img
          className="img2  img-fluid img-responsive"
          src={LinesImg2}
          alt="img2"
        />
      </div>

      <footer>
        <div class="container footer_container">
          <article>
            <h4>Menu</h4>
            <ul>
              <li>
                {" "}
                <Link to="/" onClick={handleLinkClick}>
                  Home
                </Link>
              </li>
              <li>
                <Link to="/about" onClick={handleLinkClick}>
                  About
                </Link>
              </li>
              <li>
                {" "}
                <Link to="/fly" onClick={handleLinkClick}>
                  Flights
                </Link>
              </li>
              <li>
                <Link to="/hajj" onClick={handleLinkClick}>
                  Hajj & Ummrah
                </Link>
              </li>
              <li>
                <Link to="/contact" onClick={handleLinkClick}>
                  Contact us
                </Link>
              </li>
              <li>
                <Link to="/Term" onClick={handleLinkClick}>
                  Term & Conditions
                </Link>
              </li>
            </ul>
          </article>
          <span className="d-lg-none">
            <br />
          </span>
          <article>
            <h4>Usefull Links</h4>
            <ul>
              <li>
                <a
                  rel="noopener noreferrer"
                  target="_blank"
                  href="https://wa.me/442038313492?text=Hi there..How can I help you ?"
                >
                  Online Support
                </a>
              </li>
              <li>
                <a
                  rel="noopener noreferrer"
                  target="_blank"
                  href="https://www.facebook.com/brightfaretravelsltd"
                >
                  Socials Support
                </a>
              </li>
              <li>
                <a
                  rel="noopener noreferrer"
                  target="_blank"
                  href="https://www.gov.uk/foreign-travel-advice"
                >
                  Travel Advice
                </a>
              </li>
              <li>
                <a
                  rel="noopener noreferrer"
                  target="_blank"
                  href="https://www.gov.uk/government/organisations/hm-passport-office"
                >
                  Passport Information
                </a>
              </li>
              <li>
                <a
                  rel="noopener noreferrer"
                  target="_blank"
                  href="https://www.gov.uk/guidance/travel-to-england-from-another-country-during-coronavirus-covid-19"
                >
                  Entering England
                </a>
              </li>
            </ul>
            <br />
            {/* <br />
            <h4 className="adresshead">Address</h4>
            <address>
              11 Ravenhall Gardens, Warwick, Warwickshire, CV34 7DD United
              Kingdom
               <br />
            </address> */}
          </article>
          <article>
            <h4>Need Help?</h4>
            <ul>
              <li>
                <a href="tel:02038313492">+44 (0) 203 831 3492</a>
              </li>
              <li>
                <Link className="navl">
                  <strong>admin@brightfare.co.uk</strong>
                </Link>
              </li>
            </ul>
          </article>
        </div>
        <div class="footer_copyright">
          <small>
            {" "}
            &copy;{new Date().getFullYear()} Brightfare Travels Ltd - All Rights
            Reserved
          </small>
        </div>
      </footer>
    </div>
  );
};

export default Footer;
