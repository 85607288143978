import React from "react";
import termimg from "../images/Terms_&_conditions.jpg";
const Term = () => {
  return (
    <>
      <section className="Terms">
        <img src={termimg} alt="termimg" className="img-fluid img-responsive" />
        <br />
        <div className="container">
          <div className="content">
            <h4>
              <strong>Reservation Terms & Conditions</strong>
            </h4>
            <p>
              Please read these carefully as the person making this booking
              (either for himself or for any other passenger) accepts all the
              below terms and conditions.
            </p>
            <br />
            <h4>
              <strong>
                {/* PASSPORT, VISA AND IMMIGRATION  REQUIREMENTS */}
                Passport, VISA & immigration Requirements
              </strong>
            </h4>
            <p>
              You are responsible for checking all these items like Passport,
              Visa (including Transit Visa) Covid-19 and other immigration
              requirements. You must consult with the relevant Embassy /
              Consulate, well before the departure time for the up to date
              information as requirements may change time to time. We cannot
              accept any liability of any transit visa and if you are refused
              the entry onto the flight or into any country due to failure on
              your part to carry the correct passport, visa or other documents
              required by any airline, authority Or country. While traveling, if
              you misplace your passport or visa then without delay speak to the
              respective embassy and consulate. Check that your visa and
              passports are not expired or out of date. Check the passport and
              visa requirements for the countries you are visiting / transiting
              through- make sure you apply for visas well in advance of your
              date of travel. On the travel day, do not forget to bring your
              visa and passport with you.
            </p>
            <h4>
              <strong>
                {/* CHECK TRAVEL ADVISORY RELATED TO YOUR TRIP */}
                Check Travel Advisory Related to you Trip
              </strong>
            </h4>
            <p>
              The British government has advised against all but essential
              travel. Please do check the entry requirements for your
              destination. Please also check the guidelines from given links
              https://www.gov.uk/foreign-travel-advice.
              https://www.gov.uk/guidance/travel-to-england-from-another-country-during-coronavirus-covid-19
              https://www.gov.uk/government/organisations/hm-passport-office
            </p>
            <br />
            <h4>
              <strong>
                {/* RECONFIRMING TICKET */}
                Reconfirming Ticket
              </strong>
            </h4>
            <p>
              On your invoice/ticket all details about the flights and airlines
              like the flight number, schedule and destination airport are shown
              properly. It is your responsibility to RECONFIRM your flights at
              least 72 hours before your departure time either with your travel
              agent or the relevant Airline directly. The company will not be
              liable for any additional costs due to your failure to reconfirm
              your flights. All Passengers are requested to check in at least 3
              hours before departure.
            </p>
            <br />
            <h4>
              <strong>
                {/* CANCELATIONS AND REFUNDS */}
                Cancelations & Refunds
              </strong>
            </h4>
            <p>
              After the ticket is issued, airline refund and cancellation policy
              must apply. Different airlines have different refund policies and
              once the tickets are issued, then we are bound to follow their
              rules with regards to refunds of the tickets. After the ticket is
              issued and part journey/ one-way ticket have been used then also,
              the airline airfare refund rules apply as we must abide by their
              refund rules. In case of death of a passenger, the death
              certificates must be presented to apply/request full refund of the
              unused/ partially used tickets, which we must forward to the
              respective airline. For non-refundable tickets, only a small
              portion of refund in forms of some taxes shall be paid back to us
              from the airline and we shall forward the same to the customer.
              (Company Margins and Profits are non-Refundable). You may cancel
              your booking at any time. Cancellation requests must be sent to us
              in writing to Customer Services, Brightfare Travels Ltd, by email
              to info@brightfare.co.uk, by the lead name on your booking and it
              will not take effect until received by us. Upon receipt of your
              cancellation request, the principal(s) or supplier(s) may be
              entitled to charge a cancellation fee as specified in their terms
              and conditions. Cancellation fees may be as much as 100% of the
              total price of the travel service depending on when cancellation
              occurs. Bookings are usually non-refundable unless stated
              otherwise any time after the date of booking. In addition, as we
              incur administration costs in processing your cancellation
              request, we charge an administration fee per passenger.
            </p>
            <br />
            <h4>
              <strong>
                {/* AMENDMENTS */}
                Amendments
              </strong>
            </h4>
            <p>
              For changing dates, we charge £25 as our admin charges on each
              passenger plus the date change cost, fare and tax difference costs
              according to the availability.
            </p>
            <br />
            <h4>
              <strong>
                {/* INSTALLMENT PLAN POLICY */}
                Installment Plan Policy
              </strong>
            </h4>
            <p>
              Booked Airline terms and conditions apply to your payments
              installment Plan. All fares will be subject to booked airline
              terms and conditions and airline is the only party who can make
              the changes into schedule and fares without any prior
              notifications, if airline make any changes into the reservation
              regarding fares or timings it’s our duty to follow their rules.
              You pay to us for travel arrangements will be held on behalf of
              the supplier/principal concerned, with the exception of bookings
              protected by an ATOL. We can change your holiday price after
              you’ve booked, only in certain circumstances, Changes in the price
              of the carriage of passengers resulting from changes to the cost
              of fuel or other power sources the level of taxes or fees imposed
              by third parties including tourist taxes, landing taxes or
              embarkation or disembarkation fees at ports and airports or
              exchange rates mean that the price of your travel arrangements may
              change after you have booked.
            </p>
            <br />
            <h4>
              <strong>
                {/* CHECKING ALL FLIGHT DETAILS & PASSENGER NAME(S) */}
                Checking All Flight Details & Passenger Name(S)
              </strong>
            </h4>
            <p>
              It is your responsibility to check all the details are correct
              i.e. Passenger names (are same as appearing on passport / travel
              docs), Traveling dates, Transit Time, Origin & Destination, Stop
              Over, Baggage Allowance and other flight information. Once the
              ticket is issued then no changes can be made.
            </p>
            <br />
            <h4>
              <strong>
                {/* DEPOSITS AND TICKETS ARE NEITHER REFUNDABLE NOR CHANGEABLE (T & C May Apply) */}
                Deposite & Tickets are Neither Refundable Nor changeable ( T&C
                May Apply)
              </strong>
            </h4>
            <p>
              Unless Specified, All the deposits paid and tickets purchased /
              issued are non-refundable in case of cancellation or no show
              (Failure to arrive at Departure airport on time) and
              non-changeable before or after departure (date change is not
              permitted). Once flights are reserved, bookings / tickets are
              non-transferable to any other person means that name changes are
              not permitted. Issued tickets are also not re-routable. If you are
              reserving the flight by making the advance partial payment
              (Initial deposit) then please note that fare/taxes may increase at
              any time without the prior notice. Its means the price is not
              guaranteed unless ticket is issued because airline / consolidator
              has right to increase the price due to any reason. In that case we
              will not be liable and the passenger has to pay the fare/tax
              difference. We always recommend you to pay ASAP and get your
              ticket issue to avoid this situation. Furthermore if you will
              cancel your reservation due to any reason, then the paid
              deposit(s) will not be refunded. Regardless of any reason, 50 GBP
              per person will be charged, if you will cancel your reservation
              before ticket issuance. After issuance all payments are
              non-refundable.{" "}
            </p>
            <br />
            <h4>
              <strong>
                {/* INSURANCE AND BAGGAGE LOSS */}
                Insurance & Baggage Loss
              </strong>
            </h4>
            <p>
              We recommend that you purchase travel insurance. It is your
              responsibility to ensure you have valid travel insurance that
              covers your needs and also ensure that you have complied with all
              the health and vaccination requirements for the countries you are
              travailing. Advice can be obtained from your GP or travel clinic.
              We don’t accept any claim for the lost / Stolen / Damaged Baggage.
              You have to contact the relevant airline directly in that case.
            </p>
            <br />
            <h4>
              <strong>
                {/* NO SHOW POLICY ON SCHEDULED FLIGHTS */}
                No Show Policy on Scheduled Flights
              </strong>
            </h4>
            <p>
              Please note that if you have no show for your flight then the
              ticket becomes invalid and non-refundable.
            </p>
            <br />
            <h4>
              <strong>
                {/* SPECIAL REQUESTS & MEDICAL PROBLEMS */}
                Special Requests & Medical Problems
              </strong>
            </h4>
            <p>
              If you have any special requests like meal preference, Seat
              Allocation and wheelchair request etc., please advise us at time
              of issuance of ticket. We will try our best to fulfill these by
              passing this request to the relevant airline but we cannot
              guarantee and failure to meet any special request will not held us
              liable for any claim.
            </p>
            <h4>
              <strong>
                {/* MOST IMPORTANT */}
                Most Important
              </strong>{" "}
            </h4>
            <p>
              We do not accept responsibility for any financial loss if the
              airline fails to operate. Passengers will be solely responsible
              for that so it is highly recommended that separate travel
              insurance must be arranged to protect you.
            </p>
          </div>
        </div>
        <br />
      </section>
    </>
  );
};
export default Term;
