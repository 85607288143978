import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import logo from "../images/Black_Logo.png";
import StarIcon from "@mui/icons-material/Star";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import MenuOpenIcon from "@mui/icons-material/MenuOpen";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import { Helmet } from "react-helmet";

const Navbar = () => {
  // ================= DYAMICALLLY CAHNGE TITLE FUNCTION ========
  const helmet = (pageTitle) => {
    const fullTitle = `Brightfare Ltd | ${pageTitle}`;
    document.title = fullTitle;
  };

  // ================ ON SMALL SCREEN SIDEBAR FUNCTION ================
  const [state, setState] = useState({
    right: false,
  });
  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };
  const list = (anchor) => (
    <Box
      sx={{ width: anchor === "top" || anchor === "bottom" ? "auto" : 250 }}
      role="presentation"
      className="sidebarContainer"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List>
        {[
          <div className="sidebarlogo">
            <Link
              to="/"
              onClick={() => {
                handleLinkClick();
              }}
            >
              <img src={logo} alt="Bright_logo" />
            </Link>
            <br />
          </div>,
          <Link
            className="sidebarnav"
            onClick={() => {
              helmet("Home");
              handleLinkClick();
            }}
            to="/"
          >
            Home
          </Link>,
          <Link
            className="sidebarnav"
            aria-current="page"
            to="/fly"
            onClick={() => {
              helmet("Flights");
              handleLinkClick();
            }}
          >
            Flights
          </Link>,
          <Link
            className="sidebarnav"
            aria-current="page"
            to="/hajj"
            onClick={() => {
              helmet("Hajj & Ummrah");
              handleLinkClick();
            }}
          >
            Hajj & Ummrah
          </Link>,
          <Link
            className="sidebarnav"
            aria-current="page"
            to="/about"
            onClick={() => {
              helmet("About Us");
              handleLinkClick();
            }}
          >
            About
          </Link>,
          <Link
            className="sidebarnav"
            aria-current="page"
            to="/contact"
            onClick={() => {
              helmet("Contact Us");
              handleLinkClick();
            }}
          >
            Contact US
          </Link>,
        ].map((text, index) => (
          <ListItem key={text} disablePadding>
            <ListItemButton>
              <ListItemText primary={text} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );

  // ================ ON SMALL SCREEN SIDEBAR FUNCTION END ================
  const handleLinkClick = () => {
    window.scrollTo(0, 0);
  };
  // =========== SCROLLING FUNCTION =============
  const [isScrolled, setIsScrolled] = useState(false);
  // Add scroll event listener and update isScrolled state
  useEffect(() => {
    const handleScroll = () => {
      if (window.pageYOffset > 500) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <Helmet>
        <title>Brightfare Ltd</title>
      </Helmet>
      <section className="topbar d-sm-none">
        <div className="callingmbltxt d-sm-none">
          <h5>
            <a href="tel:02038313492">0203 8313 492</a>
          </h5>
          <p>
            Open 24/7,
            <br />
            so you don't have to chase the clock
          </p>
        </div>
      </section>

      <section className={`header ${isScrolled ? "fixed-top" : ""}`}>
        <div className="container">
          <div className="row">
            <div className="col-12 container">
              <nav className="navbar navbar-expand-lg">
                <div className="container-fluid">
                  {/* ============== ON SMALLL SCREEN SIDEBAR ================== */}
                  <div className="brightfare__m_logo d-sm-none col-6">
                    <div>
                      <img
                        src={logo}
                        alt="brightfare__m_logo"
                        className="img-fluid img-responsive"
                      />
                    </div>
                  </div>
                  <div className="d-lg-none">
                    {["right"].map((anchor) => (
                      <React.Fragment key={anchor}>
                        <MenuOpenIcon
                          edge="end"
                          color="inherit"
                          aria-label="menu"
                          onClick={toggleDrawer(anchor, true)}
                          style={{
                            color: "white",
                            cursor: "pointer",
                            marginRight: 30,
                            // marginTop: -15,
                          }}
                        >
                          <MenuOpenIcon />
                        </MenuOpenIcon>
                        <Drawer
                          anchor={anchor}
                          open={state[anchor]}
                          onClose={toggleDrawer(anchor, false)}
                        >
                          {list(anchor)}
                        </Drawer>
                      </React.Fragment>
                    ))}
                  </div>

                  {/* ============ SMAILL SCREEN SIDEBAR END ====================== */}

                  {/* ============ WEB NAVBAR  START ====================== */}
                  <div
                    className="nav_list collapse navbar-collapse d-sm-none"
                    id="navbarSupportedContent"
                  >
                    <ul className="navbar-nav nav_bar">
                      <li className="nav-item">
                        <div className="NavLogo_overlay">
                          <Link
                            // className="nav-link"
                            aria-current="page"
                            to="/"
                            onClick={() => {
                              helmet("Home");
                              handleLinkClick();
                            }}
                          >
                            <img
                              src={logo}
                              className="Nav_logo"
                              alt="Bright_logo"
                            />
                          </Link>
                        </div>
                      </li>
                      <li className="nav-item">
                        <Link
                          className="nav-link"
                          aria-current="page"
                          to="/"
                          onClick={() => {
                            helmet("Home");
                            handleLinkClick();
                          }}
                        >
                          Home
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link
                          className="nav-link"
                          aria-current="page"
                          to="/fly"
                          onClick={() => {
                            helmet("Flights");
                            handleLinkClick();
                          }}
                        >
                          Flights
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link
                          className="nav-link"
                          aria-current="page"
                          to="/hajj"
                          onClick={() => {
                            helmet("Hajj & Ummrah");
                            handleLinkClick();
                          }}
                        >
                          Hajj & Ummrah
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link
                          className="nav-link"
                          aria-current="page"
                          to="/about"
                          onClick={() => {
                            helmet("About Us");
                            handleLinkClick();
                          }}
                        >
                          About us
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link
                          className="nav-link"
                          aria-current="page"
                          to="/contact"
                          onClick={() => {
                            helmet("Contact Us");
                            handleLinkClick();
                          }}
                        >
                          Contact us
                        </Link>
                      </li>
                    </ul>
                  </div>
                  <div className="callingtxt">
                    <h4 href="tel:02038313492">0203 8313 492</h4>
                    <p>
                      Open 24/7
                      <br />
                      so you don't have to chase the clock
                    </p>
                  </div>
                  {/* ============ WEB NAVBAR  END ====================== */}
                </div>
              </nav>
            </div>
          </div>
        </div>
      </section>
      <div>
        <a
          className="Trust"
          target="_blank"
          rel="noreferrer"
          href="https://www.trustpilot.com/review/brightfare.co.uk"
        >
          {/* Vierifed Reviews */}
          <StarIcon style={{ color: "#03DA8D" }} />
          <StarIcon style={{ color: "#03DA8D" }} />
          <StarIcon style={{ color: "#03DA8D" }} />
          <StarIcon style={{ color: "#03DA8D" }} />
          <StarIcon style={{ color: "#03DA8D" }} />
          <h5>Trustpilot</h5>
        </a>
      </div>
    </>
  );
};

export default Navbar;
