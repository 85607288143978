import React from "react";

const Confirmation = () => {
  return (
    <>
      <div className="message container">
        <div className="jumbotron">
          <div className="ThankMsg">
            <h3>Thank you ! </h3>
            <h2>For Choosing Our Services </h2>
            <h5>
              Our Representative Will contact you
              <br /> As Soon As Possible:{" "}
            </h5>
          </div>
          <div className="QuickDial">
            <h1>
              For Quick Qoute Dial <br />
              <h3>0203 8313 492</h3>
            </h1>
          </div>
        </div>
      </div>
    </>
  );
};

export default Confirmation;
