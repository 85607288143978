import React, { useState, useEffect } from "react";
import Flights_2nd_img from "../images/Flights_2nd_img.JPG";
import { useNavigate } from "react-router-dom";
import emailjs from "emailjs-com";
import { AirlinesAPI } from "./API/AirlinesAPI";
import FlightIcon from "@mui/icons-material/Flight";

const Fly = () => {
  const navigate = useNavigate();
  // ================= DYAMICALLLY CAHNGE TITLE FUNCTION ========
  const helmet = (pageTitle) => {
    const fullTitle = `Brightfare Ltd | ${pageTitle}`;
    document.title = fullTitle;
  };
  // HOOK FOR ACTIVE CLASS ON FORM
  const [activeButton, setActiveButton] = useState("return");
  const [showReturn, setShowReturn] = useState(true);

  const handleButtonClick = (buttonType) => {
    setActiveButton(buttonType);
    if (buttonType === "oneWay") {
      setShowReturn(false);
    } else if (buttonType === "return") {
      setShowReturn(true);
    }
  };
  // =============== SCROLLING CLICK ====================
  const handleLinkClick = () => {
    window.scrollTo(0, 0);
  };
  //HOOK USING FETCH THE API
  const [data, setData] = useState([]);
  useEffect(() => {
    fetch(
      "https://raw.githubusercontent.com/L1fescape/airport-codes/master/airports.json"
    ).then((res) =>
      res.json().then((output) => {
        setData(output);
      })
    );
  });
  // =================== FIRST SEARCHING FUNCTION HOOK ===========================
  const [filteredData, setFilteredData] = useState("");
  const [selectedItem, setSelectedItem] = useState(null);
  // SEARCHING INPUT AIRPORTS FUNCTION
  const onchangetext = (event) => {
    setFilteredData(event.target.value);
    setSelectedItem(null);
  };
  const onSearch = (item) => {
    setSelectedItem(item);
    setFilteredData("");
    console.log("Selected Item", item);
  };
  // ==================== SECOND  SEARCHING INPUT AIRPORTS FUNCTION ==================================
  // SECOND SEARCH HOOK
  const [filterData, setFilterData] = useState("");
  const [selectItem, setSelectItem] = useState(null);
  const Search = (searcht) => {
    setSelectItem(searcht);
    setFilterData("");
    console.log("Destination", searcht);
  };
  const changetext = (event) => {
    setFilterData(event.target.value);
    setSelectItem(null);
  };

  // AIRLINES SEARCHING FUNCTION
  const [filterAir, setFilterAir] = useState("");
  const [selectAir, setSelectAir] = useState(null);

  const searchAir = (airline) => {
    setSelectAir(airline);
    setFilterAir("");
    console.log("Selected Airline:", airline);
  };

  const changeAirlines = (event) => {
    setFilterAir(event.target.value);
    setSelectAir(null);
  };

  //  EMAIL NOTIFICATION FUNCTION GETTING THE EMAIL
  const SendEmail = (e) => {
    e.preventDefault();
    console.log("Selected Button ID:", activeButton);
    emailjs
      .sendForm(
        "service_3ciis79",
        "template_zkjipct",
        e.target,
        "3Ftc-ODHIbWAThyqn"
      )
      .then((res) => {
        console.log(res);
      })
      .catch((err) => console.log("Clicked", err));
    navigate("/Confirmation");
  };
  return (
    <div>
      {/* =================  FLIGHT SECTION ============== */}
      <section className="d-fly">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12 mx-auto">
              <div className="row">
                <div className="col-lg-12">
                  <form onSubmit={SendEmail}>
                    <div className="FLightModes">
                      <label
                        htmlFor="returnButton"
                        className={`btn ${
                          activeButton !== "oneWay" ? "active" : ""
                        }`}
                      >
                        <input
                          type="radio"
                          id="returnButton"
                          name="check"
                          checked={activeButton !== "oneWay"}
                          onChange={() => handleButtonClick("return")}
                          value="Return"
                        />
                        <FlightIcon />
                        Return
                      </label>
                      <label
                        htmlFor="oneWayButton"
                        className={`btn ${
                          activeButton === "oneWay" ? "active" : ""
                        }`}
                      >
                        <input
                          type="radio"
                          name="check"
                          id="oneWayButton"
                          checked={activeButton === "oneWay"}
                          onChange={() => handleButtonClick("oneWay")}
                          value="Oneway"
                        />
                        <FlightIcon />
                        OneWay
                      </label>
                    </div>
                    <div className="fly_form">
                      <div className="row">
                        <div className="col-lg-6 col-md-6 col-12">
                          <label>FLYING FROM</label>
                          <div className="form-group">
                            <input
                              required
                              autoComplete="off"
                              type="text"
                              name="origin"
                              value={
                                selectedItem
                                  ? `${selectedItem.name} | ${selectedItem.city} | ${selectedItem.country} | ${selectedItem.iata}`
                                  : filteredData
                              }
                              onChange={onchangetext}
                              placeholder="City or Airport"
                              className="form-control"
                            />
                          </div>
                          <div className="dataResultContainer">
                            <div className="dataResult">
                              {data
                                .filter((item) => {
                                  const searchItem = filteredData.toLowerCase();
                                  const name = item.name.toLowerCase();
                                  const city = item.city.toLowerCase();
                                  const country = item.country.toLowerCase();
                                  const iata = item.iata.toLowerCase();

                                  // Filter based on multiple criteria
                                  return (
                                    searchItem &&
                                    (name.startsWith(searchItem) ||
                                      city.startsWith(searchItem) ||
                                      country.startsWith(searchItem) ||
                                      iata.startsWith(searchItem)) &&
                                    name !== searchItem
                                  );
                                })
                                .map((item, index) => (
                                  <p
                                    key={index}
                                    className="dataItem"
                                    onClick={() => onSearch(item)}
                                  >
                                    {item.name} | {item.city} | {item.country} |{" "}
                                    {item.iata}
                                  </p>
                                ))}
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-12">
                          <label> FLYING TO</label>
                          <div className="form-group">
                            <input
                              required
                              autoComplete="off"
                              autoCorrect="yes"
                              aria-autocomplete="none"
                              type="text"
                              name="Destination"
                              value={
                                selectItem
                                  ? `${selectItem.name} | ${selectItem.city} | ${selectItem.country} | ${selectItem.iata}`
                                  : filterData
                              }
                              onChange={changetext}
                              placeholder="City or Airport"
                              className="form-control"
                            />
                          </div>
                          <div className="dataResultContainer">
                            <div className="dataResult">
                              {data
                                .filter((ites) => {
                                  const searchIt = filterData.toLowerCase();
                                  const fName = ites.name.toLowerCase();
                                  const city = ites.city.toLowerCase();
                                  const country = ites.country.toLowerCase();
                                  const iata = ites.iata.toLowerCase();

                                  // Filter based on multiple criteria
                                  return (
                                    searchIt &&
                                    (fName.startsWith(searchIt) ||
                                      city.startsWith(searchIt) ||
                                      country.startsWith(searchIt) ||
                                      iata.startsWith(searchIt)) &&
                                    fName !== searchIt
                                  );
                                })
                                .map((tem, index) => (
                                  <p
                                    key={index}
                                    className="dataItem"
                                    onClick={() => Search(tem)}
                                  >
                                    {tem.name} | {tem.city} | {tem.country} |{" "}
                                    {tem.iata}
                                  </p>
                                ))}
                            </div>
                          </div>
                        </div>
                        <div
                          className={
                            showReturn
                              ? "col-lg-3 col-md-6 col-6"
                              : "col-lg-6 col-md-6 col-sm-12"
                          }
                        >
                          <div className="form-group">
                            <label>Departure</label>
                            <input
                              type="date"
                              className="form-control"
                              name="Departure"
                              required=""
                            ></input>
                          </div>
                        </div>
                        {showReturn && (
                          <div className="col-lg-3 col-md-6 col-6">
                            <div className="form-group">
                              <label>Return</label>
                              <input
                                type="date"
                                className="form-control"
                                name="arrival"
                                required=""
                              ></input>
                            </div>
                          </div>
                        )}
                        <div className="col-lg-2 col-md-6 col-4">
                          <div className="form-group">
                            <label>ADULTS</label>
                            <select
                              className="form-control formImput"
                              id="exampleFormControlTextarea1  ADULT"
                              name="ADULTS"
                            >
                              <option value="1">1</option>
                              <option value="2">2</option>
                              <option value="3">3</option>
                              <option value="4">4</option>
                              <option value="5">5</option>
                              <option value="6">6</option>
                              <option value="7">7</option>
                              <option value="8">8</option>
                              <option value="9">9</option>
                            </select>
                          </div>
                        </div>
                        <div className="col-lg-2 col-md-6 col-4">
                          <div className="form-group">
                            <label>CHILDREN</label>
                            <select
                              className="form-control formImput"
                              id="exampleFormControlTextarea1"
                              name="CHILDREN"
                            >
                              <option value="0">0</option>
                              <option value="1">1</option>
                              <option value="2">2</option>
                              <option value="3">3</option>
                              <option value="4">4</option>
                              <option value="5">5</option>
                              <option value="6">6</option>
                              <option value="7">7</option>
                              <option value="8">8</option>
                              <option value="9">9</option>
                            </select>
                          </div>
                        </div>
                        <div className="col-lg-2 col-md-6 col-4">
                          <div className="form-group ">
                            <label>INFANTS</label>
                            <select
                              className="form-control formImput"
                              id="exampleFormControlTextarea1"
                              name="INFANTS"
                            >
                              <option value="0">0</option>
                              <option value="1">1</option>
                              <option value="2">2</option>
                              <option value="3">3</option>
                              <option value="4">4</option>
                              <option value="5">5</option>
                              <option value="6">6</option>
                              <option value="7">7</option>
                              <option value="8">8</option>
                              <option value="9">9</option>
                            </select>
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-6">
                          <div className="form-group">
                            <label>Travel Class</label>
                            <select
                              className="form-control formImput"
                              id="exampleFormControlTextarea1"
                              name="CLASS"
                            >
                              <option disabled selected value="Any">
                                Select Cabin Class
                              </option>
                              <option value="Economy">Economy</option>
                              <option value="Enhanced Economy">
                                Enhanced Economy
                              </option>
                              <option value="Bussiness">Bussiness</option>
                              <option value="First">First</option>
                            </select>
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-6">
                          <div className="form-group">
                            <label>Airline</label>
                            <input
                              autoComplete="off"
                              required
                              type="text"
                              className="form-control"
                              placeholder="Any Specific Flight"
                              name="airline"
                              value={
                                selectAir ? `${selectAir.AirName}` : filterAir
                              }
                              onChange={changeAirlines}
                            />
                          </div>
                          <div className="AirlinesResult">
                            {AirlinesAPI.filter((airline) => {
                              const searchAirlines = filterAir.toLowerCase();
                              const name = airline.AirName.toLowerCase();
                              // Filter based on multiple criteria
                              return (
                                searchAirlines && name.includes(searchAirlines)
                              );
                            }).map((airline, index) => (
                              <p
                                key={index}
                                className="AirlinesItem"
                                onClick={() => searchAir(airline)}
                              >
                                {airline.AirName}
                              </p>
                            ))}
                          </div>
                        </div>
                        <div className="col-lg-2 col-md-6 col-12">
                          <div className="form-group ">
                            <label>Full Name</label>
                            <input
                              autoComplete="off"
                              required
                              type="Text"
                              name="name"
                              placeholder="Name"
                              className="form-control"
                            />
                          </div>
                        </div>
                        <div className="col-lg-2 col-md-6 col-12">
                          <div className="form-group ">
                            <label>Email</label>
                            <input
                              autoComplete="off"
                              required
                              type="email"
                              name="email"
                              placeholder="Email"
                              className="form-control"
                            />
                          </div>
                        </div>
                        <div className="col-lg-2 col-md-6 col-12">
                          <div className="form-group ">
                            <label>Contact</label>
                            <input
                              autoComplete="off"
                              required
                              type="tel"
                              maxLength={11}
                              name="number"
                              placeholder="Number"
                              className="form-control"
                            />
                          </div>
                        </div>
                      </div>
                      <button
                        onClick={handleLinkClick}
                        className="btn btn-warning SubmitBTn"
                      >
                        Show Flights
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="Airlinesimg container">
          <img
            style={{ width: "98%", height: "98%" }}
            src={Flights_2nd_img}
            alt="What sell in airlines"
            className="img-fluid img-responsive"
          />
        </div>
      </section>
      <br />
      <br />
    </div>
  );
};

export default Fly;
