export const fadeImages = [
  {
    url: "../Slidshow/Air France.JPG",
    alt: "img1",
  },
  {
    url: "../Slidshow/Air canada.JPG",
    alt: "img2",
  },
  {
    url: "../Slidshow/British Airways.JPG",
    alt: "img3",
  },
  {
    url: "../Slidshow/Brussels.JPG",
    alt: "img4",
  },
  {
    url: "../Slidshow/emirates.jpg",
    alt: "img5",
  },
  {
    url: "../Slidshow/Ethiopian.JPG",
    alt: "img6",
  },
  {
    url: "../Slidshow/Etihad.JPG",
    alt: "img7",
  },
  {
    url: "../Slidshow/Gulf.JPG",
    alt: "img8",
  },
  {
    url: "../Slidshow/Qatar.JPG",
    alt: "img9",
  },
  {
    url: "../Slidshow/Turkish Airline.png",
    alt: "img10",
  },
  {
    url: "../Slidshow/Kenya.JPG",
    alt: "img11",
  },
  {
    url: "../Slidshow/Virgin.JPG",
    alt: "img12",
  },
  // {
  //   url: "../Slidshow/pink_airline.png",
  //   alt: "img12",
  // },
];
