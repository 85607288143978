import React from "react";
import CustomizedAccordions from "./Accordion";

const About = () => {
  return (
    <div>
      <section className="AboutImg"> </section>
      <br />
      <div className="container AboutContent">
        <div className="row">
          <div className="col-12 mx-auto">
            <br />
            <p>
              <strong>Brightfare Travels Ltd.</strong> is a well-established
              online travel agent providing services for all your travel needs,
              from Flights to Hotels, Car Hire to Travel Insurance and much,
              much more. Our experienced specialist consultants are on hand with
              answers to all your travel queries, taking you anywhere in the
              world, with confidence and after sale support.
            </p>
            <p>
              Our Multilingual team is ready to help you 24/7 with all kind of
              travel requirements.
            </p>
            <br />
            <p>
              <strong>Brightfare Travels Ltd.</strong> stands out amongst all
              travel agents due to its immaculate travel expertise, range and
              value. Our experienced team is ready to help you to book flights
              or holiday packages. Plus, our 24x7 customer support
              representatives along with the entire website team have
              indomitable spirits, experience and knowledge which are reflected
              in the way we do business.{" "}
              <strong> Brightfare Travels Ltd. </strong>specializes in providing
              travel deal at a higher discount on standard prices. The company
              continues to grow in popularity due to its intensive tie-ups with
              leading travel suppliers which enable it to offer discounts,
              called "negotiated" or "merchant" fares to its clients.{" "}
              <strong>Brightfare Travels Ltd.</strong> not only offers
              breathtaking destinations around the world, it also owns a
              particular specialty in long and mid haul destinations
              encompassing Asia, Far East and Australia.
            </p>
            <p>
              Travel products are available over the telephone, e-mail or live
              chat enquiry. We operate leading brands, including Galileo,
              Worldspan and Sabre GDSs known for air fares distribution and
              management specialist. Close relationships with leading travel
              suppliers', enable us to offer "negotiated" discounts to our
              customers, as well as being able to often source seats at peak
              travel dates or at last minute when tickets are in short supply.
            </p>
            <br />
            <h4>
              <strong>How do we book the flights?</strong>
            </h4>
            <p>
              After reservation we reconfirm the details as an invoice
              confirmation which takes only 2 hours or less after receiving the
              confirmation from the client. The tickets are issued within 48
              hours after receiving the full payment, these are electronic and
              will be sent to your e-mail before 7 days of you departure. If
              your travel is within 7 days your ticket will be sent to you
              within 24 hours after receiving the full payment.
            </p>
            <br />
            <h4>
              <strong>Electronic Ticket</strong>
            </h4>
            <p>
              An e-ticket is an electronic ticket. It contains all the
              information shown on a paper ticket, but is stored as an
              electronic record in the airline's computer system. When the
              e-ticket has been issued, the passenger simply needs to print out
              their receipt / itinerary showing the booking reference. The
              passenger then presents the e-ticket and required information to
              the departure check-in desk.
            </p>
            <br />
            <h2>FAQ's</h2>
            <br />
            <CustomizedAccordions />
          </div>
        </div>
      </div>
      <br />
      <br />
    </div>
  );
};

export default About;
