import React, { useEffect } from "react";
const Preloader = () => {
  useEffect(() => {
    const handleWindowLoad = () => {
      // Your window load functionality
      setTimeout(() => {
        const loadingElement = document.getElementById("loading");
        if (loadingElement) {
          loadingElement.style.transition = "opacity 0.5s ease";
          loadingElement.style.opacity = "0";
          setTimeout(() => {
            loadingElement.style.display = "none";
          }, 500);
        }
      }, 2000);
    };

    window.addEventListener("load", handleWindowLoad);

    return () => {
      window.removeEventListener("load", handleWindowLoad);
    };
  }, []);

  const handleLoadingCenterClick = () => {
    const loadingElement = document.getElementById("loading");
    if (loadingElement) {
      loadingElement.style.transition = "opacity 0.5s ease";
      loadingElement.style.opacity = "0";
      setTimeout(() => {
        loadingElement.style.display = "none";
      }, 500);
    }
  };

  return (
    // <div className="preloader">
    //   <div className="loader"></div>
    // </div>
    <>
      <div id="loading">
        {/* <img id="preloader_logo_center" className="object" src={preloader_logo} alt="preloader_logo" /> */}
        <div id="loading-center" onClick={handleLoadingCenterClick}>
          <div id="loading-center-absolute">
            <div className="object" id="object_four"></div>
            <div className="object" id="object_three"></div>
            <div className="object" id="object_two"></div>
            <div className="object" id="object_one"></div>
          </div>
        </div>
      </div>

      <div className="search-area">
        <div className="search-area-inner">
          <form action="https://codexcoder.com/">
            <input
              type="text"
              name="search"
              placeholder="Type & Hits Enter..."
            />
          </form>
        </div>
      </div>
    </>
  );
};

export default Preloader;
